<template>
  <div class="page_container">
    <h-banner :bg='banner'>
      <div slot="content">
        <el-image
          fit="contain"
          :src="banner01"
          class="animate__animated animate__lightSpeedInLeft"
          :style="`width:${$store.state.isPhone?'200px':'500px'};margin-top:${$store.state.isPhone?'100px':'120px'}`"
        ></el-image>
        <el-image
          fit="contain"
          :src="banner02"
          class="animate__animated animate__lightSpeedInRight"
          :style="`width:${$store.state.isPhone?'200px':'500px'}`"
        ></el-image>
      </div>
    </h-banner>

    <div
      class="page1 page-grey"
      v-if="!$store.state.isPhone"
    >
      <div class="clearfix page-container">
        <h-title msg="公司简介"></h-title>
        <!-- <el-image
          :src="page1Banner"
          fit="cover"
          style="height:260px"
        ></el-image> -->
        <!-- <p class="title">北京中智软创信息技术有限公司</p> -->
        <p class="desc">中智软创，国家级高新技术企业，是一家专注于化工行业生产管控及智能优化一体化的工业软件产品及服务商，以先进技术提升化工行业的信息化、智能化应用水平，符合国家“两化融合”、“智能制造”、 “工业互联网”等的政策支持方向。公司以“中智工业智慧管控平台”整体解决方案及MES、集成优化、先进过程控制等细分产品线为切入点，提升客户的生产管控水平及经济效益，持续为客户创造价值。 公司总部位于北京，于江苏、山东、江西设有子公司，于西安、苏州、天津设有办事处，于无锡和成都设立技术研发中心共150余人，其中研发团队50余人。</p>
        <!-- 利用技术领先优势，显著 -->

        <!-- <div class="ei">
          <div class="cn">企业介绍</div>
          <div class="en">Enterprise introduction</div>
        </div> -->
      </div>
    </div>

    <div
      class="p-page1"
      v-if="$store.state.isPhone"
    >
      <p class="p-title">关于我们</p>
      <p class="desc">中智软创，国家级高新技术企业，是一家专注于化工行业生产管控及智能优化一体化的工业软件产品及服务商，以先进技术提升化工行业的信息化、智能化应用水平，符合国家“两化融合”、“智能制造”、 “工业互联网”等的政策支持方向。公司以“中智工业智慧管控平台”整体解决方案及MES、集成优化、先进过程控制等细分产品线为切入点，提升客户的生产管控水平及经济效益，持续为客户创造价值。 公司总部位于北京，于江苏、山东、江西设有子公司，于西安、苏州、天津设有办事处，于无锡和成都设立技术研发中心共150余人，其中研发团队50余人</p>
      <!-- 利用技术领先优势，显著 -->
    </div>

    <div
      class="page2"
      v-if="!$store.state.isPhone"
    >
      <div class="clearfix page-container">
        <div class="page2_content">
          <div class="page-l">
            <div class="title">
              企业资质
            </div>
            <div>
              <div class="num">30+</div>
              <div class="cn">自主知识产权</div>

              <div class="num">10+</div>
              <div class="cn">行业各项资质荣誉证书</div>
            </div>
          </div>

          <div class="page-r">
            <el-row :gutter="30">
              <el-col
                v-for="(item1,i1) in list2"
                :key="item1.title+i1"
                :span="8"
              >
                <el-card
                  :body-style="{ padding: '15px',background: '#F6F7FB'}"
                  el-card
                  shadow="never"
                  class="card animate__animated animate__fadeInUp animate__delay-0.5s"
                >
                  <el-image
                    fit="contain"
                    :src="item1.img"
                    class="image"
                  ></el-image>
                  <div style="padding: 14px;">
                    <p class="title-2">{{item1.title}}</p>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>

        </div>
      </div>
    </div>

    <div
      class="p-page2 page-grey"
      v-if="$store.state.isPhone"
    >
      <div class="page-l">
        <p class="p-title">企业资质</p>
        <div class="p-num">
          <div>
            <div class="num">30+</div>
            <div class="cn">自主知识产权</div>
          </div>
          <div>
            <div class="num">10+</div>
            <div class="cn">行业各项资质荣誉证书</div>
          </div>
        </div>
      </div>
      <div
        class="page-r"
        style="padding: 15px"
      >
        <el-row>
          <el-col
            v-for="(item1,i1) in list2"
            :key="item1.title+i1"
            :span="12"
          >
            <el-card
              :body-style="{ padding: '15px',height: '175px'}"
              el-card
              shadow="never"
              class="card animate__animated animate__fadeInUp animate__delay-0.5s"
            >
              <el-image
                fit="contain"
                :src="item1.img"
                style="width:120px;height:120px;margin:0 auto;"
              ></el-image>
              <div style="padding: 14px;">
                <p class="title-2">{{item1.title}}</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- <div
      class="page3 page-grey"
      v-if="!$store.state.isPhone"
    >
      <h-title msg="企业文化"></h-title>
      <div class="page3-pro-container">
        <el-row>
          <el-col
            v-for="(item1,i2) in list3"
            :key="item1.title+i2"
            class="page3-list-item"
            :span="8"
          >
            <div
              :class="`item ${i2===page3Index&&'active'}`"
              :style="`${i2!=2&&'border-right:1px dashed #ffffff'}`"
              @mouseenter="handleME(i2)"
              @mouseleave="handleLE()"
            >
              <div class="page3-list-item-in">
                <div class="page3-list-item-bottom">
                  <div class="title">
                    {{item1.title}}
                  </div>
                  <div
                    v-html="item1.desc"
                    class="desc"
                  >
                  </div>
                </div>
              </div>
              <div class="page3-list-item-out">
                <div class="page3-list-item-bottom">
                  <div class="title">
                    {{item1.title}}
                  </div>
                  <div
                    v-html="item1.desc"
                    class="desc"
                  >
                    {{item1.desc}}
                  </div>

                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div> -->

    <!-- <div
      class="p-page3"
      v-if="$store.state.isPhone"
    >

      <div>
        <div class="p-content">
          <el-row :gutter="15">
            <el-col
              v-for="(item1,i2) in plist3"
              :key="item1.title+i2"
              :span="24"
            >
              <span>{{item1.desc}}</span>
              <el-divider content-position="right">{{item1.title}}</el-divider>
            </el-col>
          </el-row>
        </div>
      </div>
    </div> -->

    <div
      class="page4"
      v-if="!$store.state.isPhone"
    >
      <div class="clearfix page-container">
        <h-title msg="联系我们"></h-title>
        <div class="page-content">
          <div class="page4-l">
            <div>
              <div
                v-for="(item,i) in list4"
                :key="item.title"
                :class="['page4-l-item',{'page4-l-item_active':page4Index==i}]"
                @mouseenter="page4Index=i"
              >
                {{item.title}}
              </div>
            </div>

            <div class="actitle">
              <div
                class="desc"
                v-html="list4[page4Index].desc"
              ></div>

              <p class="info">{{list4[page4Index].phone}}</p>
              <p class="info">{{list4[page4Index].email}}</p>
              <p class="info">{{list4[page4Index].address}}</p>
            </div>
          </div>
          <div class="page4-r">
            <img :src="list4[page4Index].img" alt="" style="width:520px;margin-top:-60px;margin-left:40px">
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="$store.state.isPhone"
      class="page-grey p-page4"
    >
      <p class="p-title">联系我们</p>
      <el-collapse
        v-model="activeName"
        accordion
      >
        <el-collapse-item
          v-for="(item,i) in list4"
          :key="item.title"
          :title="item.title"
          :name="i+1"
        >
          <div class="page2-item">
            <div class="footer-top_info">
              <p>{{item.phone}}</p>
              <p>{{item.email}}</p>
              <p>{{item.address}}</p>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div
      class="page5"
      v-if="!$store.state.isPhone"
    >
      <div class="clearfix page-container">
        <div class="footer-body">
          <p class="title">CONTACT US</p>
          <p class="desc">快速的服务响应速度，贴合品牌客户机敏的市场反应机制</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      banner: require('../../assets/about/ab-banner.png'),
      banner01: require("../../assets/about/a-banner1-1.png"),
      banner02: require("../../assets/about/a-banner1-2.png"),
      page1Banner: require("../../assets/about/ab-page1-img1.png"),
      dot: require("../../assets/about/a-dot.png"),
      activeName: 1,
      list1: [
        { title: '合作企业伙伴', num: 269 },
        { title: '企业规模（人）', num: 150 },
        { title: '核心产品专利授权', num: 46 },
        { title: '完成项目数量(个)', num: 198 },
      ],
      list2: [
        { img: require('../../assets/about/ab-page2-img1.png'), title: '机械联合会会员单位' },
        { img: require('../../assets/about/ab-page2-img2.png'), title: '华为鲲鹏云认证' },
        { img: require('../../assets/about/ab-page2-img3.png'), title: '企业核心竞争力评价' },
        { img: require('../../assets/about/ab-page2-img4.png'), title: '国家级高新技术企业' },
        { img: require('../../assets/about/ab-page2-img5.png'), title: '软件企业证书' },
        { img: require('../../assets/about/ab-page2-img6.png'), title: '软件企业证书' },
      ],
      list3: [
        { img: require('../../assets/home5-4.png'), title: '企业愿景', desc: '不求大而全，但求精而专；<br/>以精湛的技术和专业的服务赢得客户信任与市场份额；<br/>成长为中国MES领域的领导者。' },
        { img: require('../../assets/home5-5.png'), title: '企业使命', desc: '充分发掘企业各执行环节中的信息资源，持续优化其业务流程，提升其竞争力；<br/>融合先进技术，提升其生产力，让企业赢在执行；<br/>提升企业管理水平，为企业创造价值，驱动企业进步。' },
        { img: require('../../assets/home5-6.png'), title: '企业目标', desc: '聚焦智能制造，让CIS-IMES在中国普遍成功；<br/>及时、准时、实时地把握变化，为全球企业提供高水平的企业执行管理信息平台。' },
      ],

      plist3: [
        { img: require('../../assets/home5-4.png'), title: '企业愿景', desc: '不求大而全，但求精而专；以精湛的技术和专业的服务赢得客户信任与市场份额；成长为中国MES领域的领导者。' },
        { img: require('../../assets/home5-5.png'), title: '企业使命', desc: '充分发掘企业各执行环节中的信息资源，持续优化其业务流程，提升其竞争力；融合先进技术，提升其生产力，让企业赢在执行；提升企业管理水平，为企业创造价值，驱动企业进步。' },
        { img: require('../../assets/home5-6.png'), title: '企业目标', desc: '聚焦智能制造，让CIS-IMES在中国普遍成功；及时、准时、实时地把握变化，为全球企业提供高水平的企业执行管理信息平台。' },
      ],

      list4: [
        { title: '北京',img:require('../../assets/about/ab-page4-bj.png'), desc: '北京<br />中智软创信息技术有限公司', phone: '电话：010-53658815', email: '邮箱：mail@cisinfo.cn', address: '地址：北京市海淀区知春路甲48号盈都大厦B座6层', },
        { title: '江苏',img:require('../../assets/about/ab-page4-js.png'), desc: '江苏<br />中智软创信息技术有限公司', phone: '电话：0510-83597797', email: '邮箱：mail@cisinfo.cn', address: '地址：无锡市惠山经济开发区智慧路22号中智科技园2号楼708', },
        // { title: '苏州',img:require('../../assets/about/ab-page4-js.png'), desc: '江苏<br />中智软创信息技术有限公司', phone: '电话：010-8819-2088', email: '邮箱：mail@cisino.cn', address: '地址：天津市滨海新区塘沽贻信康庭3-1-1702', },
        { title: '天津',img:require('../../assets/about/ab-page4-tj.png'), desc: '天津<br />办事处', phone: '电话：010-53658815', email: '邮箱：mail@cisino.cn', address: '地址：天津市滨海新区塘沽营口道泛华国际大厦9层A-1', },
        // { title: '江西',img:require('../../assets/about/ab-page4-jx.png'), desc: '江西<br />中智软创信息技术有限公司', phone: '电话：010-88192088', email: '邮箱：mail@cisinfo.cn', address: '地址：江西省赣州市赣江源大道15星海天城5栋406', },
        // { title: '山东',img:require('../../assets/about/ab-page4-sd.png'), desc: '山东<br />中智软创信息技术有限公司', phone: '电话：010-88192088', email: '邮箱：mail@cisinfo.cn', address: '地址：山东省济宁市高新区海川路9号产学研基地D2三楼309室', },
        { title: '成都',img:require('../../assets/about/ab-page4-cd.png'), desc: '成都<br />分公司', phone: '电话：010-53658815', email: '邮箱：mail@cisinfo.cn', address: '地址：四川省成都市武侯区益州大道北段1639号环球中心w1区9-1-620', },
        { title: '西安',img:require('../../assets/about/ab-page4-xa.png'), desc: '西安<br />办事处', phone: '电话：010-53658815', email: '邮箱：mail@cisinfo.cn', address: '地址：西安市雁塔区高新六路26号融信科技园密斯楼6楼', },
      ],

      page3Index: -1,
      page4Index: 0,
    }
  },
  mounted() {
  },
  methods: {

    handleME(i) {
      this.page3Index = i
    },
    handleLE() {
      this.page3Index = -1
    },
  }
}
</script>

<style lang="scss" scoped>
.page_container {
  background-color: #fff;
  .el-collapse {
    border: none;
  }
  .el-collapse-item {
    border-radius: 5px;
    padding: 10px 20px;
    background: #fff;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .p-page4 {
    padding-bottom: 20px;
  }

  .h-title {
    padding-bottom: 45px;
  }
  .page1 {
    padding-bottom: 50px;

    .page-container {
      position: relative;
    }
    .title {
      font-size: 1.6em;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 400;
      color: #0124c9;
      line-height: 100px;
      text-align: center;
    }
    .desc {
      font-size: 0.825em;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #666666;
      line-height: 34px;
      padding-left: 60px;
      // border-left: 1px solid #264bf8;
      margin-left: 50px;
    }

    .ei {
      position: absolute;
      right: -100px;
      top: 320px;
      color: #fff;
      background-color: #264bf8;
      padding: 50px;
      .cn {
        font-size: 1em;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 33px;
      }
    }
  }

  .p-title {
    text-align: center;
    font-size: 1.625em;
    line-height: 50px;
    border-bottom: 3px solid #264bf8;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .p-page1 {
    .desc {
      line-height: 30px;
      padding: 10px 20px;
      font-size: 1em;
      color: #323232;
    }
  }
  .image {
    width: 200px;
    height: 150px;
  }

  .p-page2 {
    .title {
      font-size: 1.6em;
    }
    .p-num {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      text-align: center;
    }
    .num {
      color: #264bf8;
      font-size: 2em;
      font-weight: 500;
      margin-left: 20px;
    }
    .cn {
      font-size: 0.825em;
      margin-bottom: 25px;
      margin-left: 20px;
    }
  }

  .page2 {
    .page2_content {
      padding: 60px 0 30px 0;
      display: flex;
      flex-direction: row;

      .page-l {
        width: 300px;
        .title {
          font-size: 1.6em;
          margin-bottom: 80px;
        }
        .num {
          color: #264bf8;
          font-size: 2em;
          font-weight: 500;
        }
        .cn {
          font-size: 0.825em;
          margin-bottom: 35px;
        }
      }
      .page-r {
        width: 780px;
        .card {
          margin-bottom: 25px;
          :hover img {
            transform: scale(1.2);
          }
        }
        .title-2 {
          font-size: 0.825em;
          text-align: center;
        }
      }
    }

    .list_item:hover {
      -ms-transform: translateY(-15px);
      transform: translateY(-15px);
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
      transition: all 0.4s ease-in-out;
      cursor: pointer;
    }
  }

  .page3 {
    .page3-pro-container {
      display: flex;
      flex-direction: row;
      background: url("../../assets/about/ab-page3-img1.png") no-repeat;
      background-size: cover;
      .el-row {
        width: 100%;
      }
      .page3-list-item {
        position: relative;
        height: 100%;
        overflow: hidden;
        .item {
          .page3-list-item-in {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 350px;
            color: #fff;
          }
          .page3-list-item-out {
            position: absolute;
            color: #fff;
            left: 0;
            right: 0;
            height: 350px;
            bottom: -350px;
            background: #264bf8;
            display: flex;
            justify-content: center;
            align-items: center;
            .detail {
              cursor: pointer;
              border: 1px solid #fff;
              margin-top: 20px;
              display: block;
              width: 120px;
              line-height: 26px;
              border-radius: 20px;
              font-size: 0.625em;
              margin-left: auto;
              margin-right: auto;
            }
            .detail-bottom {
              font-size: 0.625em;
              border-top: 1px dashed #fff;
              text-align: left;
              line-height: 45px;
              margin-top: 45px;
            }
          }
        }
        .active {
          // .page3-list-item-in {
          //   display: none;
          // }
          .page3-list-item-out {
            bottom: 0;
            -webkit-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
          }
        }
        .page3-list-item-bottom {
          text-align: center;
          .title {
            font-size: 1em;
            margin-top: 15px;
            margin-bottom: 20px;
          }
          .desc {
            font-size: 0.625em;
            line-height: 1.8em;
          }
        }

        .page3-list-item-out :hover {
          -ms-transform: top(0px);
          transform: top(0px);
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }

  .p-page3{
    .p-content{
      background: url("../../assets/about/ab-page3-img1.png") no-repeat;
      background-size: cover;
      padding:30px;
      color: #fff;
    }
  }

  .page4 {
    background: url("../../assets/about/ab-page4-img1.png") no-repeat;
    background-size: cover;
    height: 650px;
    .h-title {
      color: #fff;
    }
    .page-content {
      display: flex;
      flex-direction: row;
      padding-top: 50px;
      .page4-l {
        display: flex;
        flex-direction: row;
        min-width: 500px;
        .page4-l-item {
          color: #b6b6b6;
          line-height: 32px;
          font-size: 1.2em;
          margin-right: 55px;
          cursor: pointer;
        }
        .page4-l-item_active {
          color: #f8c400;
        }

        .actitle {
          color: #ffffff;
          .desc {
            color: #ffffff;
            line-height: 38px;
            font-size: 1.6em;
            margin-bottom: 35px;
          }
          .info {
            font-size: 0.825em;
            line-height: 2em;
          }
        }
      }
    }
  }

  .page5 {
    width: 100%;
    height: 210px;
    background-image: url("../../assets/cloud/c-banner5.png");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: 1920px;
    .page-container {
      .footer-body {
        width: 1200px;
        margin: 0 auto;
        padding-top: 60px;
        text-align: center;
        .title {
          font-size: 2em;
          font-weight: 500;
          color: #ffffff;
          line-height: 45px;
        }
        .desc {
          font-size: 1.25em;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
